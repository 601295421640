module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Timberart","short_name":"Timber","start_url":"/gatsby-starter-eventually/","background_color":"#000","theme_color":"#000","display":"standalone","icon":"src/assets/img/website-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a8a0b61aaa0c3e59cc0cb66492fa2f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-BFNB0N8X4D","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":true,"sampleRate":5,"siteSpeedSampleRate":10,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
